<template>
  <v-card class="overflow-hidden">
    <v-app-bar
        dark
        height="auto"
    >
      <v-toolbar-title>
        {{ $store.getters.user.name }}, {{ role }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
          bottom
          left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="yellow"
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, i) in items[$store.getters.user.role]"
              :key="i"
              :to="item.url"
          >
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>
              Выйти
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-slot:extension>
        <v-btn v-for="(link, index) in links[$store.getters.user.role]" :key="index" :to="link.url">
          <span class="hidden-sm-and-down">{{ link.name }}</span>
        </v-btn>
      </template>
    </v-app-bar>
    <v-main>
      <v-sheet
          id="scrolling-techniques-4"
      >
        <v-container>
          <slot></slot>
        </v-container>
      </v-sheet>
    </v-main>
  </v-card>
</template>

<script>
export default {
  name: "SecondHeaderButtons",
  data() {
    return {
      drawer: true,
      links:  {
        1: [
          {
            url:  "/ork/new-orders",
            name: 'НОВЫЕ ЗАЯВКИ'
          },
          {
            url:  "/ork/ordered",
            name: 'НАЗНАЧЕННЫЕ ЗАЯВКИ'
          },
          {
            url:  "/ork/prepare-to-client",
            name: 'ПОДГОТОВКА К СОГЛАСОВАНИЮ ДЛЯ КЛИЕНТА'
          },
          {
            url:  "/ork/client-approval",
            name: 'НА СОГЛАСОВАНИЕ КЛИЕНТУ'
          },
          {
            url:  "/ork/ork-work",
            name: 'ЗАЯВКИ В РАБОТЕ'
          },
          {
            url:  "/ork/repair-completed",
            name: 'РЕМОНТ ВЫПОЛНЕН'
          },
          {
            url:  "/ork/returned-orders",
            name: 'ВОЗВРАЩЕННЫЕ ЗАЯВКИ'
          },
          {
            url:  "/ork/closed-orders",
            name: 'ЗАКРЫТЫЕ ЗАЯВКИ'
          },
          {
            url:  "/ork/canceled-orders",
            name: 'ОТМЕНЕННЫЕ ЗАЯВКИ'
          },
          {
            url:  "/ork/all-orders",
            name: 'ВСЕ ЗАЯВКИ'
          },
        ],
        2: [
          {
            url: "/client/new-orders", name: 'НОВЫЕ ЗАЯВКИ'
          },
          {
            url: "/client/diagnostic", name: 'НАЗНАЧЕНА ДИАГНОСТИКА'
          },
          {
            url: "/client/on-diagnostic", name: 'НА ДИАГНОСТИКЕ'
          },
          {
            url: "/client/wait-for-client", name: 'НА СОГЛАСОВАНИИ'
          },
          {
            url: "/client/orders-in-work", name: 'ЗАЯВКИ В РАБОТЕ'
          },
          {
            url: "/client/completed-orders", name: 'ВЫПОЛНЕННЫЕ ЗАЯВКИ'
          },
          {
            url: "/client/closed-orders", name: 'ЗАКРЫТЫЕ ЗАЯВКИ'
          },
          {
            url: "/client/rejected-orders", name: 'ОТМЕНЁННЫЕ ЗАЯВКИ'
          },
          {
            url: "/client/all-orders", name: 'ВСЕ ЗАЯВКИ'
          },
        ],
        3: [
          {
            url: "/orpu/", name: 'НОВЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpu/prep-for-aggr", name: 'ПОДГОТОВКА К СОГЛАСОВАНИЮ'
          },
          {
            url: "/orpu/for-client-approval", name: 'НА СОГЛАСОВАНИЕ КЛИЕНТУ'
          },
          {
            url: "/orpu/orders-in-work", name: 'ЗАЯВКИ В РАБОТЕ'
          },
          {
            url: "/orpu/completed-orders", name: 'ВЫПОЛНЕННЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpu/canceled-orders", name: 'ОТМЕНЁННЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpu/closed-orders", name: 'ЗАКРЫТЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpu/returned-orders", name: 'ВОЗВРАЩЕННЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpu/all-orders", name: 'ВСЕ ЗАЯВКИ'
          },
        ],
        4: [
          {
            url: "/orpa/new-orders", name: 'НОВЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpa/orders-for-agree-ork", name: 'ЗАЯВКИ НА СОГЛАСОВАНИИ У ОРК'
          },
          {
            url: "/orpa/orders-for-agree-client", name: 'ЗАЯВКИ НА СОГЛАСОВАНИЕ КЛИЕНТУ'
          },
          {
            url: "/orpa/orders-on-pay", name: 'НА ОПЛАТЕ'
          },
          {
            url: "/orpa/closed-orders", name: 'ЗАКРЫТЫЕ ЗАЯВКИ'
          },
          {
            url: "/orpa/all-orders", name: 'ВСЕ ЗАЯВКИ'
          },
          {
            url: "/orpa/returned-orders", name: 'ВОЗВРАЩЕННЫЕ ЗАЯВКИ'
          },
        ],
        5: [
          {
            url: "/station/new-orders", name: 'НОВЫЕ ЗАЯВКИ'
          },
          {
            url: "/station/orders-on-diagnostics", name: 'ДИАГНОСТИКА'
          },
          {
            url: "/station/approval-orders", name: 'ЗАЯВКИ НА СОГЛАСОВАНИЕ SUPPLY'
          },
          {
            url: "/station/client-approval-orders", name: 'ЗАЯВКИ НА СОГЛАСОВАНИЕ КЛИЕНТУ'
          },
          {
            url: "/station/orders-in-work", name: 'В РАБОТЕ'
          },
          {
            url: "/station/completed-orders", name: 'ВЫПОЛНЕННЫЕ ЗАЯВКИ'
          },
          {
            url: "/station/orders-wait-for-pay", name: 'НА ОПЛАТЕ'
          },
          {
            url: "/station/closed-orders", name: 'ЗАКРЫТЫЕ ЗАЯВКИ'
          },
          {
            url: "/station/all-orders", name: 'ВСЕ ЗАЯВКИ'
          },
          {
            url: "/station/canceled-orders", name: 'ОТМЕНЕННЫЕ ЗАЯВКИ'
          },
          {
            url: "/station/returned-orders", name: 'ВОЗВРАЩЕННЫЕ ЗАЯВКИ'
          },
        ],
        6: [
          {
            url: "/pay/new-orders", name: 'НОВЫЕ ЗАЯВКИ'
          },
          {
            url: "/pay/orders-in-work", name: 'ЗАЯВКИ В РАБОТЕ'
          },
          {
            url: "/pay/returned-orders", name: 'ВОЗВРАЩЕННЫЕ ЗАЯВКИ'
          },
          {
            url: "/pay/closed-orders", name: 'ЗАКРЫТЫЕ ЗАЯВКИ'
          },
          {
            url: "/pay/all-orders", name: 'ВСЕ ЗАЯВКИ'
          },
          {
            url: "/pay/canceled-orders", name: 'ОТМЕНЁННЫЕ ЗАЯВКИ'
          },
        ],
        7: [],
        8: []
      },
      items:  {
        1: [
          {
            color: "primary",
            url:   '/',
            name:  'Статусы по заявкам'
          },
          {
            color: "primary",
            url:   '/ork/companies',
            name:  'Список компаний'
          },
          {
            color: "primary",
            url:   '/ork/bills/formed',
            name:  'Счета для клиента'
          },
          {
            color: "primary",
            url:   '/ork/status-cars',
            name:  'Исправность автомобилей'
          },
          {
            color: "secondary",
            url:   '/CRM_инструкция для Клиентов.pdf',
            name:  'Инструкция по работе в CRM'
          },
        ],
        2: [
          {
            color: "success",
            url:   "/client/request",
            name:  'Создать заявку'
          },
          {
            color: "primary",
            url:   "/",
            name:  'Статусы по заявкам'
          },
          {
            color: "primary",
            url:   "/client/bills",
            name:  'Счета'
          },
          {
            color: "primary",
            url:   "/client/status-cars",
            name:  'Исправность автомобилей'
          },
          {
            color: "secondary",
            url:   "/CRM_инструкция для Клиентов.pdf",
            name:  'Инструкция по работе в CRM'
          }
        ],
        3: [
          {
            color: 'primary',
            url:   '/orpu/',
            name:  'Статусы по заявкам'
          },
          {
            color: 'primary',
            url:   '/orpu/stations',
            name:  'СТО'
          },
          {
            color: 'secondary',
            url:   '/CRM_инструкция для Клиентов.pdf',
            name:  'Инструкция по работе в CRM'
          }
        ],
        4: [
          {
            color: 'primary',
            url:   '/',
            name:  'Статусы по заявкам'
          },
          {
            color: 'primary',
            url:   '/orpa/shop',
            name:  'Магазины автозапчастей'
          },
          {
            color: 'secondary',
            url:   '/CRM_инструкция для Клиентов.pdf',
            name:  'Инструкция по работе в CRM'
          }
        ],
        5: [
          {
            color: 'primary',
            url:   '/',
            name:  'Статусы по заявкам'
          },
          {
            color: 'secondary',
            url:   '/CRM_инструкция для Клиентов.pdf',
            name:  'Инструкция по работе в CRM'
          }
        ],
        6: [
          {
            color: 'primary',
            url:   '/',
            name:  'Статусы по заявкам'
          },
          {
            color: 'secondary',
            url:   '/CRM_инструкция для Клиентов.pdf',
            name:  'Инструкция по работе в CRM'
          }
        ],
        7: [
          {
            color: 'secondary',
            url:   '/CRM_инструкция для Клиентов.pdf',
            name:  'Инструкция по работе в CRM'
          }
        ],
        8: [
          {
            url:  "/admin/orders",
            name: "Заявки",
          },
          {
            url:  "/admin/station",
            name: "СТО",
          },
          {
            url:  "/admin/companies",
            name: "Компании",
          },
          {
            url:  "/admin/contracts",
            name: "Контракты",
          },
          {
            url:  "/admin/regions",
            name: "Регионы",
          },
          {
            url:  "/admin/banks",
            name: "Банки",
          },
          {
            url:  "/admin/car-types",
            name: "Типы машин"
          },
          {
            url:  "/admin/users",
            name: "Пользователи",
          },
          {
            url:  "/admin/shops",
            name: "Магазины",
          },
        ]
      }
    }
  },
  computed: {
    role() {
      switch (this.$store.getters.user.role) {
        case 1:
          return 'Менеджер Отдела по Работе с Клиентами'
        case 2:
          return 'Клиент'
        case 3:
          return 'Менеджер Отдела по Работе с партнёрами'
        case 4:
          return 'Менеджер Отдела по Работе с автозапчастями'
        case 5:
          return 'СТО'
        case 6:
          return 'Менеджер Отдела по платежам'
        case 7:
          return 'Бухгалтер'
        case 8:
          return 'Администратор'
        case 9:
          return "Руководство клиента"
      }
      return 'Неизвестный'
    }
  },
  methods:  {
    logout: function () {
      this.$store.commit('setUser', {
        token: "",
        role:  0,
        name:  ""
      },);
      this.$router.push("/");
      this.notifications = [];
    },
  },
  mounted() {
    this.$http.get('count/orders', {
      params: {
        statuses: '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24'
      }
    })
      .then((response) => {
        console.log(response.data)
      })
  }
}
</script>

<style scoped>
.v-sheet header {
  height:         auto;
  display:        flex;
  flex-direction: column;
  gap:            25px;
}
</style>