<template>
  <div>
    <form @submit="sendEmail">
      <div class="main">
        <div class="grey-bg">
          <div class="container">
            <div class="inner-title">
              <h3>Восстановление пароля</h3>
            </div>
            <div class="register-input">
              <input v-model="request.email" name="email" placeholder="Ваш e-mail" required type="email">
              <input v-model="request.password" name="password" placeholder="Новый пароль" required type="password">
              <span v-if="passwordEmpty">Введите пароль</span>
              <input v-model="request.password_confirmation" name="password_confirmation"
                     placeholder="Подтвердите пароль" required
                     type="password">
              <span v-if="!PasswordsMatch">Пароли не совпадают<br></span>
              <button :disabled="!PasswordsMatch" type="submit">Сменить пароль</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      request:       {
        email:                 "",
        password:              "",
        password_confirmation: "",
        token:                 this.$route.params.token
      },
      passwordEmpty: false
    }
  },
  props:    {},
  watch:    {},
  created:  {},
  methods:  {
    sendEmail: function (e) {
      e.preventDefault();
      if (this.request.password.length > 0) {
        var self = this;
        this.$http.post('/password/reset', this.request).then(function (response) {
          self.$store.commit('setUser', response.data.user);
          localStorage.setItem('role', response.data.role);
          localStorage.setItem('token', response.data.token);
          self.$emit('successlogin')
          self.$router.push('/')
        })
      } else {
        this.passwordEmpty = true;
      }
    }
  },
  computed: {
    PasswordsMatch: function () {
      if (this.request.password != this.request.password_confirmation) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.main {
  height: 74vh
}

.grey-bg {
  height: 100%;
}
</style>