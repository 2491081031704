<template>
  <form @submit="RegisterSend">
    <input v-model="registerClient.FamilyName" placeholder="Фамилия" required type="text">
    <input v-model="registerClient.name" placeholder="Имя" required type="text">
    <input v-model="registerClient.FatherName" placeholder="Отчество" required type="text">
    Компания
    <select v-if="!loading" id="" v-model="registerClient.company" name="" required>
      <option v-for="(company, index) in companies" v-bind:key="index" :value="company.id">
        {{ company.name }}
      </option>
    </select>
    <div v-if="loading" class="loading">
      Загружаем компании...
    </div>
    <input v-model="registerClient.phone" placeholder="Телефон представителя" required type="phone">
    <input v-model="registerClient.email" placeholder="Email представителя" required type="email">
    <span v-if="incorrectEmail">Введённый e-mail не является корректным. Возможно он уже занят</span>
    <input v-model="registerClient.address" placeholder="Город представителя" required type="text">
    <input v-model="registerClient.password" placeholder="Пароль" required type="password">
    <span v-if="shortPass">Пароль должен быть более 7 символов длиной</span>
    <button class="register-btn" type="submit">
      ЗАРЕГИСТРИРОВАТЬСЯ
    </button>
  </form>
</template>

<script type="text/javascript">
export default {
  name:    'ClientCreationForm',
  created: function () {
    this.getCompanies();
  },
  data() {
    return {
      loading:        true,
      companies:      [],
      registerClient: {
        FamilyName:            '',
        name:                  "",
        FatherName:            "",
        company:               0,
        phone:                 "",
        email:                 "",
        agreement:             false,
        address:               "",
        password:              "",
        password_confirmation: ""
      },
      incorrectEmail: false,
    }
  },
  methods:  {
    getCompanies: function () {
      this.$http.get('/companies')
          .then(response => {
            this.companies = response.data.data;
            this.loading   = false;
          })
    },
    RegisterSend: function (e) {
      e.preventDefault();
      var self = this;
      this.$http.post('/register/client', this.registerClient)
          .then(function (response) {
            // if(response.data.role){
            self.$store.commit('setUser', response.data.user);
            localStorage.setItem('role', response.data.role_id);
            localStorage.setItem('token', response.data.token);
            self.$emit('successlogin')
            self.$router.push('/')					// }
          })
          .catch(error => {
            console.log(error.response.data.errors);
            // if (error.response.data.errors.hasOwnProperty("email")) {
            //   this.incorrectEmail = true
            // }
            // if (error.response.data.errors.hasOwnProperty("password")) {
            //   this.incorrectEmail = true
            // }
            // if (error.response.data.errors.hasOwnProperty("email")) {
            //   this.incorrectEmail = true
            // }
          })
    }
  },
  computed: {
    shortPass: function () {
      if (this.registerClient.password.length < 8) {
        return true;
      } else {
        return false;
      }
    },
    samePass:  function () {
      if (this.registerClient.password != this.registerClient.password_confirmation) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>
<style scoped>
button.register-btn {
  font-family:    Montserrat, sans-serif;
  font-style:     normal;
  font-weight:    500;
  font-size:      13px;
  line-height:    16px;
  text-align:     center;
  letter-spacing: -0.183529px;
  color:          #ffffff;
}

.agreement {
  display: block;
  width:   1px;
  height:  1px;
}
</style>