<template>
  <div>
    <div class="main">
      <div class="grey-bg">
        <div class="container">
          <div class="inner-title">
            <h3>Регистрация</h3>
          </div>
          <div class="register-input">
            <select v-model="current">
              <option value="0">Клиент</option>
              <option value="1">Менеджер СТО</option>
              <option value="2">Руководство клиента</option>
            </select>
            <transition name="fade">
              <RegisterClient v-if="current == 0" @successlogin="successLogin"/>
            </transition>
            <transition name="fade">
              <RegisterStationManager v-if="current == 1"/>
            </transition>
            <!-- <transition name="fade">
              <RegisterGuest v-if="current == 2" @successlogin="successLogin"/>
            </transition> -->
            <!--        <transition name="fade">
                                    <RegisterShopManager v-if="current == 2" />
                        </transition>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import RegisterClient from "@/components/Client/Register.vue";
// import RegisterGuest from "@/components/Guest/Register.vue";
import RegisterStationManager from "@/components/Station/Register.vue";
// import RegisterShopManager from '@/components/Shop/Register.vue'

export default {
  components: {
    RegisterClient,
    RegisterStationManager
    // RegisterShopManager
  },
  data() {
    return {
      current: 0
    };
  },
  created() {
  },
  methods: {
    successLogin: function () {
      this.$emit("successlogin");
    }
  }
};
</script>
<style scoped type="text/css">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
