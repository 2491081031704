<template>
  <form @submit="RegisterSendClient">
    <input v-model="registerStation.name" placeholder="ФИО" required type="text"/>
    <select v-if="!loading" id v-model="registerStation.station" name required>
      <option
          v-for="(station, index) in stations"
          v-bind:key="index"
          :value="station.id"
      >{{ station.name }}
      </option>
    </select>
    <div v-if="loading" class="loading">Загружаем компании...</div>
    <input v-model="registerStation.email" placeholder="Email" required type="email"/>
    <input v-model="registerStation.password" placeholder="Пароль" required type="password"/>
    <input
        v-model="registerStation.password_confirmation"
        placeholder="Пароль"
        required
        type="password"
    />
    <div class="remember-me">
      <div class="form-group">
        <input id="css" v-model="registerStation.agreement" required type="checkbox"/>
        <label for="css">
          <p>
            Принять
            <a href>соглашение</a>
          </p>
        </label>
      </div>
    </div>
    <div v-if="!passwordLengthValid">Пароль должен быть длиннее 8 символов</div>
    <div v-if="!passwordMatchValid">Пароли не совпадают</div>
    <button
        v-if="passwordLengthValid && passwordMatchValid"
        class="register-btn"
        type="submit"
    >ЗАРЕГИСТРИРОВАТЬСЯ
    </button>
  </form>
</template>

<script type="text/javascript">
export default {
  name:    'CreateStation',
  created: function () {
    this.getStations();
  },
  data() {
    return {
      loading:         true,
      stations:        [],
      registerStation: {
        name:                  "",
        station:               0,
        email:                 "",
        agreement:             0,
        password:              "",
        password_confirmation: ""
      }
    };
  },
  methods:  {
    getStations:        function () {
      this.$http
          .get("/stations")
          .then(response => {
            this.stations = response.data;
            this.loading  = false;
          });
    },
    RegisterSendClient: function (e) {
      e.preventDefault();
      var self = this;
      this.$http
          .post(
              "/register/station",
              this.registerStation
          )
          .then(response => {
            self.$store.commit("setUser", response.data.user);
            localStorage.setItem("role", response.data.role_id);
            localStorage.setItem("token", response.data.token);
            self.$emit("successlogin");
            self.$router.push("/");
          });
    }
  },
  computed: {
    passwordLengthValid: function () {
      return this.registerStation.password.length > 8 ? true : false;
    },
    passwordMatchValid:  function () {
      return this.registerStation.password ==
      this.registerStation.password_confirmation
          ? true
          : false;
    }
  }
};
</script>

<style scoped type="text/css">
</style>