<template>
  <div>
    <form @submit="sendEmail">
      <div class="main">
        <div class="grey-bg">
          <div class="container">
            <div class="inner-title">
              <h3>Восстановление пароля</h3>
            </div>
            <div class="register-input">
              <input v-model="request.email" name="email" placeholder="Ваш e-mail" required type="email">
              <button type="submit">Запросить восстановление пароля</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      request: {
        email: ""
      }
    }
  },
  props:   {},
  watch:   {},
  created: {},
  methods: {
    sendEmail: function (e) {
      e.preventDefault();
      this.$http.post('/password/create', this.request)
          .then(() => {
            this.$swal.fire("На вашу почту отправлена ссылка на страницу восстановления пароля")
          })
    }
  }
}
</script>

<style scoped>
.main {
  height: 74vh
}

.grey-bg {
  height: 100%;
}
</style>