<template>
  <div>
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col-sm-9">
          </div>
          <div class="col-sm-3">
            <div class="login wow fadeInRight">
              <form @submit="signin">
                <input v-model="login.email" placeholder="Email" type="email">
                <input v-model="login.password" placeholder="Пароль" type="password">
                <div class="remember-me">
                  <div class="form-group">
                    <input id="css" v-model="login.remember" type="checkbox">
                    <label for="css">Запомнить меня</label>
                  </div>
                </div>
                <div class="enter-btn">
                  <button type="submit">ВОЙТИ</button>
                </div>
                <div class="forgot-pass">
                  <router-link to="/password/email">Забыли пароль?</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      login: {
        email:    "",
        password: "",
        remember: false
      }
    }
  },
  methods: {
    signin: function (e) {
      e.preventDefault();
      this.$http.post('/login', this.login)
          .then(response => {
            this.$store.commit('setUser', {
              token: response.data.token,
              role:  response.data.role,
              name:  response.data.user.name,
            })
            this.$emit('successlogin')
            this.$router.push('/')
          })
          .catch(error => {
            if (error.response.data === "Password missmatch") {
              this.$swal.fire('Неверный пароль')
            }
            if (error.response.data === "User does not exist") {
              this.$swal.fire('E-Mail не найден в базе данных')
            }
          })
    }
  }
}
</script>
