<template>
  <div>
    <v-container fluid style="max-width: 100% !important;">
      <div
          style="display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; margin-bottom: 30px">
        <img src="/images/logo2.png" style="height: 108px;" alt="">
        <v-spacer/>
        <div style="display: flex; justify-content: space-between; gap: 20px; flex-wrap: wrap;">
          <div data-v-98feeed2="" style="display: flex; align-items: center; gap: 10px;">
            <div data-v-98feeed2="" style="border: 2px solid green; border-radius: 25px; padding: 7px;">
              <div class="v-image v-responsive theme--light" data-v-98feeed2="">
                <div class="v-responsive__sizer" style="padding-bottom: 112.5%;"></div>
                <div class="v-image__image v-image__image--cover"
                     style="background-image: url(/images/geo.png); background-position: center center;"></div>
                <div class="v-responsive__content" style="width: 16px;"></div>
              </div>
            </div>
            <p data-v-98feeed2=""> г. Нур-Султан, пр. Туран, 1 </p></div>
          <v-spacer/>
          <div data-v-98feeed2="" style="display: flex; align-items: center; gap: 10px;">
            <div data-v-98feeed2="" style="border: 2px solid green; border-radius: 25px; padding: 7px;">
              <div class="v-image v-responsive theme--light" data-v-98feeed2="">
                <div class="v-responsive__sizer" style="padding-bottom: 112.5%;"></div>
                <div class="v-image__image v-image__image--cover"
                     style="background-image: url(/images/phone.png); background-position: center center;"></div>
                <div class="v-responsive__content" style="width: 16px;"></div>
              </div>
            </div>
            <p data-v-98feeed2="">8 800 080 09 11 1 </p></div>
          <v-spacer/>
          <v-dialog
              v-model="showLogin"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="green"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                Вход для сотрудников
              </v-btn>
            </template>
            <v-card>
              <v-toolbar
                  color="primary"
                  dark
                  style="height: auto"
              >
                <v-btn
                    dark
                    icon
                    @click="showLogin = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Вход для сотрудников</v-toolbar-title>
              </v-toolbar>
              <router-view/>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-container>
    <v-card elevation="20" style="    background-size: cover;
    background-image: url('/images/background.png');">
      <v-container fluid style="max-width: 100% !important; padding-top: 80px;padding-left: 80px">
        <v-row>
          <v-col cols="12" md="5">
            <p style="color: white; font-size: 18pt; line-height: 40px">
              <span style="color: #043a5c; font-size: 20pt;">Supply Group KZ</span> - уникальная в своем сегменте рынка
              компания по <span style="color: #043a5c;">комплексному</span> техническому обслуживанию и
              ремонту автотранспортных средств <span style="color: #043a5c;">любого уровня сложности</span>
              в <span style="color: #043a5c;">любой точке Казахстана</span>
            </p>
            <v-btn style="margin-top: 20px; padding: 20px">
              <v-icon>mdi-whatsapp</v-icon> &nbsp;&nbsp; Написать нам
            </v-btn>
          </v-col>
          <v-col cols="0" md="1"/>
          <v-col cols="12" md="6">
            <img src="/images/car.png" style="transform: scaleX(-1); width: 100%" alt="">
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container fluid style="max-width: 100% !important;">
      <v-row style="margin-top: 30px;">
        <v-col cols="12" style="text-align: center">
          <h2>Отремонтировано более 18 000 автомобилей</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid style="max-width: 100% !important;">
      <v-row style="padding-bottom: 30px; margin-bottom: 30px; background-image: url('/images/wheel.png')">
        <v-col cols="12" md="12">
          <div style="display: flex; justify-content: space-evenly; flex-wrap: wrap; gap: 20px;">
            <v-card style="width: 350px; height: 240px; display: flex; justify-content: center;">
              <div style="display: flex; justify-content: center; flex-direction: column; text-align: center;">
                <h1 style="color: #8cc63f;">
                  3300
                </h1>
                <h4>
                  Автомобилей
                </h4>
                <br/>
                <br/>
                <h4 style="color: #15537a">
                  За 2019 год
                </h4>
              </div>
            </v-card>
            <v-card style="width: 350px; height: 240px; display: flex; justify-content: center;">
              <div style="display: flex; justify-content: center; flex-direction: column; text-align: center;">
                <h1 style="color: #8cc63f;">
                  6294
                </h1>
                <h4>
                  Автомобилей
                </h4>
                <br/>
                <br/>
                <h4 style="color: #15537a">
                  За 2020 год
                </h4>
              </div>
            </v-card>
            <v-card style="width: 350px; height: 240px; display: flex; justify-content: center;">
              <div style="display: flex; justify-content: center; flex-direction: column; text-align: center;">
                <h1 style="color: #8cc63f;">
                  8771
                </h1>
                <h4>
                  Автомобилей
                </h4>
                <br/>
                <br/>
                <h4 style="color: #15537a">
                  За 2021 год
                </h4>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card elevation="20" style="background-color: #15537a; color: white;">
      <v-container fluid style="max-width: 100% !important;">
        <v-row style="padding: 80px;">
          <v-col cols="12" md="6">
            <h2>
              --- Наша сеть СТО
            </h2>

            <v-list style="background-color: #15537a;" three-line>
              <template v-for="(item, index) in items">
                <v-list-item
                    :key="index"
                >
                  <div style="display: flex; flex-wrap: wrap; align-items: center">
                    <v-list-item-avatar size="100px" style="background-color: #8cc63f; margin-right: 20px">
                      <v-img :src="item.avatar"/>
                    </v-list-item-avatar>
                    <h5 style="color: white">
                      {{ item.title }}
                    </h5>
                  </div>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col cols="12" md="6">
            <div style="display: flex; flex-direction: column; gap: 20px">
              <img src="/images/map.png" alt=""/>
              <h2>
                Более 150 официальных партнеров по всему Казахстану
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container fluid style="font-size: 20pt; line-height: 40px; max-width: 100% !important;">
      <v-row style="margin-top: 30px">
        <v-col cols="12" md="6" style="display: flex; align-items: center; justify-content: end;">
          <div style="display: flex; flex-direction: column;">
            <h2>--- Как мы работаем</h2>
            <p>
              Мы предлагаем в бесплатное пользование Клиента<br/>
              уникальную систему Customer Relationship Management<br/>
              (Управление Взаимоотношениями с Клиентами, далее - CRM)<br/>
              собственной разработки - программное обеспечение,<br/>
              предназначенное для автоматизации взаимодействия<br/>
              с Клиентами с целью улучшения их обслуживания.<br/>
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" style="display: flex; flex-direction: column; align-items: center">
          <img src="/images/valley.png" style="width: 30rem; max-width: 100%;" alt="">
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" style="display: flex; flex-direction: column; align-items: center">
          <img src="/images/crm.png" style="width: 30rem; max-width: 100%;" alt="">
        </v-col>
        <v-col cols="12" md="6" style="display: flex; flex-direction: column; align-items: center">
          <p>Основные функции CRM:</p>
          <ol>
            <li>Создавать, отменять, дополнять заявки и отслеживать</li>
            ход выполнения заявок в режиме онлайн.
            <li>Контролировать как была выполнена услуга с помощью</li>
            фото и видео отчетов.
            <li>Согласовывать услуги и тарифы, принимать документы
              (дефектный акт, акт выполненных работ, счет на оплату)
              в режиме онлайн.
            </li>
            <li>Сохранять историю ремонтов и формировать отчеты.</li>
            <li>Производить фильтр и сортировку заявок для удобного поиска.</li>
            <li>Контролировать оприходование бюджетов на ремонты.</li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
    <v-card elevation="20"
            style="background-color: #15537a; background-image: url('/images/cars.png'); background-size: cover; color: white; padding-bottom: 30px">
      <v-container fluid style="max-width: 100% !important;">
        <v-row style="padding: 80px;">
          <v-col cols="12" md="6">
            <h2>
              --- Процесс обработки заявки
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div style="display: flex; justify-content: space-evenly; flex-wrap: wrap; gap: 20px">
              <div class="e33630">
                <div class="e3352">
                  <div class="e33510"></div>
                  <span class="e33515">1</span>
                  <span class="e33524">Создание Заявки Клиентом</span>
                </div>
              </div>

              <div class="e33630">
                <div class="e3352">
                  <div class="e33510"></div>
                  <span class="e33515">2</span>
                  <span class="e33524">Диагностика автотранспортного средства</span>
                </div>
              </div>

              <div class=e33629>

              </div>

              <div class="e33630">
                <div class="e3352">
                  <div class="e33510"></div>
                  <span class="e33515">3</span>
                  <span class="e33524">Формирование и согласование Дефектного акта</span>
                </div>
              </div>
              <div class=e33629>
              </div>
              <div class="e33630">
                <div class="e3352">
                  <div class="e33510"></div>
                  <span class="e33515">4</span>
                  <span class="e33524">Выполнение ремонта</span>
                </div>
              </div>
              <div class=e33629>
              </div>

              <div class="e33630">
                <div class="e3352">
                  <div class="e33510"></div>
                  <span class="e33515">5</span>
                  <span class="e33524">Оплата</span>
                </div>
              </div>
              <div class=e33629>
              </div>
            </div>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%">
            <img src="/images/logo2.png" style="width: 108px" alt=""/>
            <p>
              Мы ценим безопасность сотрудников
              Клиента – даем гарантию на свои
              услуги и автозапчасти.
            </p>
            <p>
              Мы находим самые удобные для
              клиентов и партнеров решения.
            </p>
            <p>
              Мы ценим время Клиента – его
              автотранспорт всегда на ходу.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <div id="map" style="width:100%; height:100%"></div>
        </v-col>
        <v-col cols="12" md="4">
          <v-list style="background: none;" three-line>
            <template v-for="(item, index) in itemsBottom">
              <v-list-item
                  :key="index"
              >
                <div style="display: flex; align-items: center; gap: 10px">
                  <div style="border: 2px solid green; border-radius: 25px; padding: 7px ">
                    <v-img :src="item.avatar"/>
                  </div>
                  <p>
                    {{ item.title }}
                  </p>
                </div>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <div style="display: flex; justify-content: center; align-items: center;  width: 100%">
          <p>SupplyGroup</p>
          <img src="/images/logomin.png" alt="">
          <p>2022 office@supplygroup.kz</p>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DG from "2gis-maps";

export default {
  name: "LandingPage",
  mounted() {
    let map = DG.map('map', {
      'center': [43.210119, 76.925724],
      'zoom':   13
    });
    DG.marker([43.210119, 76.925724]).addTo(map);
  },
  data() {
    return {
      showLogin: false,
      items:     [
        {
          avatar: '/images/partner-icon.png',
          title:  'Не менее 3 официальных ПАРТНЕРОВ в каждом регионе Казахстана'
        },
        {
          avatar: '/images/sto.png',
          title:  'Оригинальные АВТОЗАПЧАСТИ от официальных поставщиков'
        }
      ],

      itemsBottom: [
        {
          avatar: '/images/email.png',
          title:  'Центр поддержки клиентов'
        },
        {
          avatar: '/images/phone.png',
          title:  'Приемная'
        },
        {
          avatar: '/images/phone.png',
          title:  'Телефон доверия'
        },
        {
          avatar: '/images/geo.png',
          title:  'г. Нур-Султан, пр. Туран, 1'
        },
      ]
    }
  }
}
</script>

<style scoped>
.e33630 {
  width:  202px;
  height: 197px;
}

.e3352 {
  background-color:           rgba(255, 255, 255, 1);
  width:                      202px;
  height:                     197px;
  position:                   relative;
  left:                       0;
  top:                        0;
  border-radius:              10px;
}

.e33629 {
  width:    59px;
  height:   59px;
  position: absolute;
  left:     72px;
  top:      19px;
}

.e33510 {
  border-radius:    10px;
  background-color: rgba(137.00000703334808, 193.00000369548798, 63.000000044703484, 1);
  width:            59px;
  height:           59px;
  position:         absolute;
  left:             70px;
  top:              19px;
}

.e33515 {
  color:          rgba(255, 255, 255, 1);
  width:          18px;
  height:         45px;
  position:       absolute;
  left:           90px;
  top:            25px;
  font-family:    Roboto,serif;
  text-align:     left;
  font-size:      30px;
  letter-spacing: 0;
}

.e33524 {
  color:          rgba(0, 0, 0, 1);
  width:          166px;
  height:         54px;
  position:       absolute;
  left:           19px;
  top:            99px;
  font-family:    Roboto,serif;
  text-align:     center;
  font-size:      18px;
  letter-spacing: 0;
}
</style>