<template>
  <v-app>
    <div v-if="$store.getters.loader"
         style="position: absolute; top: 0; left: 0; background-color: white; z-index: 5; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <v-progress-circular
          :size="200"
          color="primary"
          indeterminate
      />
    </div>
    <SecondHeaderButtons v-if="$store.getters.user.token">
      <div id="mainBody">
        <router-view/>
      </div>
    </SecondHeaderButtons>
    <Landing v-else />
    <v-snackbar
        v-model="snackBar.snack"
        :color="snackBar.color"
        :timeout="2000"
        class="d-flex"
        right
    >
      <div class="font-weight-medium m-0 p-0">
        {{ snackBar.text }}
      </div>
    </v-snackbar>
  </v-app>
</template>
<script type="text/javascript">
import SecondHeaderButtons from "@/components/SecondHeaderButtons";
import {mapState} from 'vuex'
import Landing from "@/components/Landing";

export default {
  components: {
    Landing,
    SecondHeaderButtons
  },
  computed:   {
    ...mapState(["snackBar", "loader"])
  },
  data() {
    return {
      showRegisterHeader:        true,
      notifications:             [],
      act:                       {},
      totalAmount:               [],
      totalPrice:                [],
      totalPriceWithMarkUp:      [],
      totalAmountWorks:          0,
      totalPriceWorks:           0,
      totalPriceWithMarkUpWorks: 0,
      ACWData:                   false,
      ACWType:                   "",
      ACWBill:                   "",
    };
  },
  created: function () {
    // eslint-disable-next-line no-console
    console.log(
        "%c+",
        'font-size: 1px; padding: 150px 87px; line-height: 0; background: url("https://cs.pikabu.ru/assets/images/dev.png"); background-size: 175px 300px; color: transparent;'
    );
    if (localStorage.getItem("token")) {
      this.showRegisterHeader = false;
    }
    if (this.$store.getters.user.token.length > 0) {
      this.fetchNotifications();
    }
    // window.Echo.private(
    //   "App.User." + JSON.parse(localStorage.getItem("user")).id
    // ).notification(notification => {
    //   this.notifications.push(notification);
    //
    //   const audio = new Audio(require("./assets/sounds/for-sure.mp3"));
    //   audio.play();
    // });
  },
  methods: {
    fetchNotifications() {
      this.$http
          .get("/notifications/unread")
          .then(response => {
            this.notifications = response.data;
          });
    },
  }
};
</script>
<style scoped type="text/css">
</style>
