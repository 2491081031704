import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});
Vue.use(Vuex)

export default new Vuex.Store({
    state:     {
        user:     {
            token: "",
            role:  0,
            name:  ""
        },
        snackBar: {
            snack: false,
            text:  "",
            color: "",
        },
        loader:   false
    },
    mutations: {
        SHOW_LOADER:          (state, data) => {
            state.loader = data
        },
        SHOW_SNACK_BAR:       (state, data) => {
            state.snackBar = data;
        },
        SHOW_SNACK_BAR_ERROR: (state, error) => {
            state.snackBar = {
                snack: true,
                color: 'red',
                text:  `Ошибка: ${error.response.status}`
            }
        },
        SHOW_SNACK_BAR_SAVED: (state) => {
            state.snackBar = {
                snack: true,
                color: 'green',
                text:  'Сохранено'
            }
        },
        setUser:              function (state, user) {
            state.user = user;
        },
    },
    actions:   {},
    getters:   {
        user:   state => {
            return state.user
        },
        loader: state => {
            return state.loader
        }
    },
    plugins:   [vuexLocal.plugin],
})
