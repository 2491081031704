import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import SmartTable from 'vuejs-smart-table'
// import VueGoodTablePlugin from 'vue-good-table';
// import 'vue-good-table/dist/vue-good-table.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment'
// import {library} from '@fortawesome/fontawesome-svg-core'
// import {faShare, faBell} from '@fortawesome/free-solid-svg-icons'
// import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
// import Echo from "laravel-echo"
import VCalendar from 'v-calendar';
import VueMask from 'v-mask'
import i18n from './i18n'
import vuetify from './plugins/vuetify'

Vue.use(VueMask);
Vue.use(VCalendar, {
    componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

// library.add([faShare, faBell]);
// Vue.component('font-awesome-icon', FontAwesomeIcon);
// Vue.component('pagination-laravel', require('laravel-vue-pagination'));

// Vue.prototype.moment = moment

Vue.use(VueSweetalert2);

axios.interceptors.request.use(
    (config) => {
        let token = store.getters.user.token;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
// Vue.use(VueGoodTablePlugin);

axios.interceptors.request.use(
    function (request) {
        store.commit('SHOW_LOADER', true)
        return request
    },
    function () {
        store.commit("SHOW_LOADER", false)
    }
);

axios.interceptors.response.use(
    function (response) {
        store.commit("SHOW_LOADER", false)
        return response;
    },
    function (error) {
        // Do something with response error
        store.commit("SHOW_LOADER", false)
        return Promise.reject(error);
    }
);

Vue.use(vuetify, {
    iconfont: "mdi"
});

Vue.prototype.$http                                         = axios;
Vue.prototype.$http.defaults.baseURL                        = 'https://api.clickauto.app/api'
Vue.prototype.$http.defaults.headers.common['Content-Type'] = 'multipart/form-data';

router.beforeEach((to, from, next) => {
    if (to.name === "main" || to.name === "Login" || to.name === "Register" || to.name === "PasswordEmail" || to.name === "PasswordReset") {
        next()
    } else {
        axios.post('/auth/check')
            .then(() => next())
            .catch(() => {
                next('/login')
            })
    }
})

// Vue.use(SmartTable)
Vue.config.productionTip = false

// ! web socket starting
// window.io = require('socket.io-client');
// // Have this in case you stop running your laravel echo server
// if (typeof io !== 'undefined') {
// 	window.Echo = new Echo({
// 		broadcaster: 'socket.io',
// 		host: window.location.hostname + ':6001',
// 		auth: { headers: { Authorization: "Bearer " + localStorage.getItem('token') } },
// 		authEndpoint: '/api/broadcasting/auth'
// 	});
// }

const vm = new Vue({
    router,
    store,
    i18n,
    vuetify,
    moment,
    render: h => h(App)
}).$mount('#app');

window.vm = vm
