import Vue from 'vue'
import Router from 'vue-router'
import RegisterView from '@/views/RegisterView.vue'
import LoginView from '@/views/LoginView.vue'
import PasswordEmail from '@/views/PasswordEmail.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import store from "@/store";

Vue.use(Router)

const roles = [
    {
        role: 1,
        url:  '/ork/new-orders'
    },
    {
        role: 2,
        url:  '/client/new-orders'
    },
    {
        role: 3,
        url:  '/orpu'
    },
    {
        role: 4,
        url:  '/orpa/new-orders'
    },
    {
        role: 5,
        url:  '/station/new-orders'
    },
    {
        role: 6,
        url:  '/pay/new-orders'
    },
    {
        role: 7,
        url:  '/accountant'
    },
    {
        role: 8,
        url:  '/admin/orders'
    },
    {
        role: 9,
        url:  '/guest/  '
    }
];

export default new Router({
    routes: [
        {
            path:        '/',
            name:        'main',
            component:   LoginView,
            beforeEnter: (to, from, next) => {
                var result = roles.filter(obj => {
                    return obj.role === store.getters.user.role;
                })
                if (Array.isArray(result) && result.length > 0) {
                    next(result[0].url)
                } else {
                    next('/login')
                }
            }
        },
        {
            path:      '/login',
            name:      'Login',
            component: LoginView,
        },
        {
            path:      '/register',
            name:      'Register',
            component: RegisterView,
        },
        {
            path:      '/password/email',
            name:      'PasswordEmail',
            component: PasswordEmail,
        },
        {
            path:      '/password/reset/:token',
            name:      'PasswordReset',
            component: PasswordReset,
        },
        {
            path:      "/guest",
            name:      'GuestPage',
            component: () => import(/* webpackChunkName: "accountant" */ '@/components/Guest/Statuses.vue'),
        },
        {
            path:      '/admin',
            name:      'AdminView',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin/View.vue'),
            children:  [
                {
                    path:      'orders',
                    name:      'OrdersView',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Order/Model.vue'),
                },
                {
                    path:      'orders/:id',
                    name:      'AdminEditOrder',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Order/EditAdd.vue'),
                },
                {
                    path:      'station',
                    name:      'AdminStation',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Stations/index.vue')
                },
                {
                    path:      'station/:id',
                    name:      'AdminStationInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Stations/EditStation.vue')
                },
                {
                    path:      'companies',
                    name:      'AdminCompanies',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Companies/index.vue')
                },
                {
                    path:      'companies/:id',
                    name:      'AdminCompaniesInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Companies/EditCompany.vue')
                },
                {
                    path:      'contracts',
                    name:      'AdminContracts',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Contracts/index.vue')
                },
                {
                    path:      'contracts/:id',
                    name:      'AdminContractsInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Contracts/EditContract.vue')
                },
                {
                    path:      'regions',
                    name:      'AdminRegions',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Regions/index.vue')
                },
                {
                    path:      'regions/:id',
                    name:      'AdminRegionsInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Regions/EditRegion.vue')
                },
                {
                    path:      'banks/',
                    name:      'AdminBanks',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Banks/index.vue')
                },
                {
                    path:      'banks/:id',
                    name:      'AdminBanksInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Banks/EditBank.vue')
                },
                {
                    path:      'car-types',
                    name:      'AdminCarTypes',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/CarTypes/index.vue')
                },
                {
                    path:      'car-types/:id',
                    name:      'AdminCarTypesInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/CarTypes/EditCarType.vue')
                },
                {
                    path:      'drivers',
                    name:      'AdminDrivers',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Drivers/index.vue')
                },
                {
                    path:      'drivers/:id',
                    name:      'AdminDriversInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Drivers/EditDriver.vue')
                },
                {
                    path:      'users/',
                    name:      'AdminUsers',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Users/index.vue')
                },
                {
                    path:      'users/:id',
                    name:      'AdminUsersInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Users/EditUser.vue')
                },
                {
                    path:      'shops/',
                    name:      'AdminShops',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Shops/index.vue')
                },
                {
                    path:      'shops/:id',
                    name:      'AdminShopsInner',
                    component: () => import(/* webpackChunkName: "admin" */ '@/components/Admin/Shops/EditShop.vue')
                }
            ]
        },
        {
            path:        '/client',
            component:   () => import(/* webpackChunkName: "client" */ '@/views/Client/View.vue'),
            children:    [
                {
                    path:      'new-orders',
                    name:      'ClientView.new-orders',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/NewOrders.vue'),
                },
                {
                    path:      'diagnostic',
                    name:      'ClientView.diagnostic',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/Diagnostic.vue'),
                },
                {
                    path:      'on-diagnostic',
                    name:      'ClientView.on-diagnostic',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/OnDiagnostic.vue'),
                },
                {
                    path:      'wait-for-client',
                    name:      'ClientView.wait-for-client',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/ClientWait.vue'),
                },
                {
                    path:      'orders-in-work',
                    name:      'ClientView.orders-in-work',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/OrdersInWork.vue'),
                },
                {
                    path:      'completed-orders',
                    name:      'ClientView.completed-orders',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/CompletedOrders.vue'),
                },
                {
                    path:      'rejected-orders',
                    name:      'ClientView.rejected-orders',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/RejectedOrders.vue'),
                },
                {
                    path:      'all-orders',
                    name:      'ClientView.all-orders',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/AllOrders.vue'),
                },
                {
                    path:      'closed-orders',
                    name:      'ClientView.closed-orders',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/new/ClosedOrders.vue'),
                },
                {
                    path:      'request',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/Request.vue'),
                },
                {
                    path:      'bills',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/Client/BillsComponent.vue'),
                },
                {
                    path:      'orders/:id/defect',
                    component: () => import(/* webpackChunkName: "client" */ '@/components/ActShow.vue'),
                },
                {
                    path:      'status-cars',
                    name:      'CarsPage.status-cars',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/CarsPage.vue'),
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.getters.user.role === 2) {
                    next()
                } else {
                    next(false)
                }
            }
        },
        {
            path:        '/station',
            component:   () => import(/* webpackChunkName: "station" */ '@/views/Station/View.vue'),
            children:    [
                {
                    path:      'new-orders',
                    name:      'StationVie.new-ordersw',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/NewOrders.vue'),
                },
                {
                    path:      'orders-on-diagnostics',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/OrderOnDiagnostics.vue'),
                },
                {
                    path:      'canceled-orders',
                    name:      'StationView.canceled-orders',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/CanceledOrders.vue'),
                },
                {
                    path:      'approval-orders',
                    name:      'StationView.approval-orders',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/OrdersForApproval.vue'),
                },
                {
                    path:      'completed-orders',
                    name:      'StationView.completed-orders',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/CompletedOrders.vue'),
                },
                {
                    path:      'closed-orders',
                    name:      'StationView.closed-orders',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/ClosedOrders.vue'),
                },
                {
                    path:      'all-orders',
                    name:      'StationVie.all-ordersw',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/AllOrders.vue'),
                },
                {
                    path:      'returned-orders',
                    name:      'StationView.returned-orders',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/ReturnedOrders.vue'),
                },
                {
                    path:      'orders-wait-for-pay',
                    name:      'StationView.orders-wait-for-pay',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/OrdersWaitForPay.vue'),
                },
                {
                    path:      'orders-in-work',
                    name:      'StationView.orders-in-work',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/OrderInWork.vue'),
                },
                {
                    path:      'client-approval-orders',
                    name:      'StationView.client-approval-orders',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/Station/new/OrdersForClientApproval.vue'),
                },
                {
                    path:      'orders/:id/defect',
                    component: () => import(/* webpackChunkName: "station" */ '@/components/ActShow.vue'),
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.getters.user.role === 5) {
                    next()
                } else {
                    next(false)
                }
            }
        },
        {
            path:        '/ork',
            component:   () => import(/* webpackChunkName: "ork" */ '@/views/Ork/View.vue'),
            children:    [
                {
                    path:      'new-orders',
                    name:      'OrkView.new-orders',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/NewOrders.vue'),
                },
                {
                    path:      'client-approval',
                    name:      'OrkView.client-approval',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/OrkClientApproval.vue'),
                },
                {
                    path:      'returned-orders',
                    name:      'OrkView.returned-orders',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/ReturnedOrders.vue'),
                },
                {
                    path:      'all-orders',
                    name:      'OrkView.all-orders',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/AllOrders.vue'),
                },
                {
                    path:      'canceled-orders',
                    name:      'OrkView.canceled-orders',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/CanceledOrders.vue'),
                },
                {
                    path:      'closed-orders',
                    name:      'OrkView.closed-orders',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/ClosedOrders.vue'),
                },
                {
                    path:      'repair-completed',
                    name:      'OrkView.repair-completed',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/RepairCompleted.vue'),
                },
                {
                    path:      'ork-work',
                    name:      'OrkView.ork-work',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/OrkWork.vue'),
                },
                {
                    path:      'ordered',
                    name:      'Ordered',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/Ordered.vue'),
                },
                {
                    path:      'prepare-to-client',
                    name:      'Ordered.prepare-to-client',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/PrepareClient.vue'),
                },
                {
                    path:      'companies',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/Companies.vue'),
                },
                {
                    path:      'companies/create',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/CreateCompany.vue'),
                },
                {
                    path:      'orders/:id/defect',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/ActShow.vue'),
                },
                {
                    path:      'order/:id/orpu',
                    name:      'OrderOrpuPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Temp/OrderORPU.vue'),
                },
                {
                    path:      'order/:id/orpa',
                    name:      'OrderOrpaPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Temp/OrderORPA.vue'),
                },
                {
                    path:      'companies/:id',
                    name:      'CompanyPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/views/Company/Company.vue'),
                },
                {
                    path:      'companies/:id/contracts',
                    name:      'CompanyContractsPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/ContractsTable.vue'),
                },
                {
                    path:      'companies/:id/contracts/create',
                    name:      'CompanyContractsPageCreate',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/ContractsEditAdd.vue'),
                },
                {
                    path:      'companies/:id/contracts/:contract',
                    name:      'CompanyContractsPageEdit',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/ContractsEditAdd.vue'),
                },
                {
                    path:      'companies/:id/drivers',
                    name:      'CompanyDriversPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/DriversTable.vue'),
                },
                {
                    path:      'companies/:id/drivers/:driver',
                    name:      'CompanyDriversPageEdit',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/DriverEditAdd.vue'),
                },
                {
                    path:      'companies/:id/drivers/create',
                    name:      'CompanyDriversPageCreate',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/DriverEditAdd.vue'),
                },
                {
                    path:      'companies/:id/cars',
                    name:      'CompanyCarsPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/CarTable.vue'),
                },
                {
                    path:      'companies/:id/cars/:car',
                    name:      'CompanyCarsPageEdit',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/CarEditAdd.vue'),
                },
                {
                    path:      'companies/:id/cars/create',
                    name:      'CompanyCarsPageCreate',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Company/CarEditAdd.vue'),
                },
                {
                    path:      'companies/:id/managers',
                    name:      'CompanyManagersPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/views/Company/Managers.vue'),
                },
                {
                    path:      'bills',
                    name:      'BillsPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/BillsComponent.vue'),
                },
                {
                    path:      'bills/formed',
                    name:      'FormedBillsPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/FormedBillsComponent.vue'),
                },
                {
                    path:      'bills/:companyId',
                    name:      'CompaniesBillsPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/CompaniesBillsComponent.vue'),
                },
                {
                    path:      'status-cars',
                    name:      'CarsPage',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/CarsPage.vue'),
                },
                {
                    path:      'add-car',
                    name:      'AddCar',
                    component: () => import(/* webpackChunkName: "ork" */ '@/components/Ork/AddCar.vue'),
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.getters.user.role === 1) {
                    next()
                } else {
                    next(false)
                }
            }
        },
        {
            path:      '/pay',
            component: () => import(/* webpackChunkName: "op" */ '@/views/OP/View.vue'),
            children:  [
                {
                    path:      'new-orders',
                    name:      'OpView.new-orders',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/OP/NewOrders.vue')
                },
                {
                    path:      'orders-in-work',
                    name:      'OpView.orders-in-work',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/OP/OrdersInWork.vue')
                },
                {
                    path:      'canceled-orders',
                    name:      'OpView.canceled-orders',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/OP/CanceledOrders.vue')
                },
                {
                    path:      'closed-orders',
                    name:      'OpView.closed-orders',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/OP/ClosedOrders.vue')
                },
                {
                    path:      'all-orders',
                    name:      'OpView.all-orders',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/OP/AllOrders.vue')
                },
                {
                    path:      'returned-orders',
                    name:      'OpView.returned-orders',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/OP/ReturnedOrders.vue')
                },
                // {
                //     path: 'account',
                //     component: () => import(/* webpackChunkName: "op" */ '@/components/OP/AccountTable.vue')
                // },
                {
                    path:      'orders/:id/defect',
                    component: () => import(/* webpackChunkName: "op" */ '@/components/ActShow.vue')
                }
            ],
            // beforeEnter: (to, from, next) => {
            // 	if (store.getters.user.role === 6) {
            // 		next()
            // 	}
            // 	else {
            // 		next(false)
            // 	}
            // }
        },
        {
            path:        '/orpa',
            component:   () => import(/* webpackChunkName: "orpa" */ '@/views/Orpa/View.vue'),
            children:    [
                // {
                //     path: '',
                //     name: 'OrpaStatuses',
                //     component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/Statuses.vue'),
                //
                // },
                {
                    path:      'new-orders',
                    name:      'Shops.new-orders',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/NewOrders.vue'),

                },
                {
                    path:      'orders-for-agree-ork',
                    name:      'Shops.orders-for-agree-ork',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/OrderForAgreementByOrk.vue'),

                },
                {
                    path:      'orders-for-agree-client',
                    name:      'Shops.orders-for-agree-client',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/OrderForAgreementByClient.vue'),

                },
                {
                    path:      'orders-on-pay',
                    name:      'Shops.orders-on-pay',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/OrdersOnPay.vue'),

                },
                {
                    path:      'closed-orders',
                    name:      'Shops.closed-orders',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/ClosedOrders.vue'),

                },
                {
                    path:      'all-orders',
                    name:      'Shops.all-orders',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/AllOrders.vue'),

                },
                {
                    path:      'returned-orders',
                    name:      'Shops.returned-orders',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/new/ReturnedOrders.vue'),

                },
                {
                    path:      'shop',
                    name:      'Shops.shop',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/Shops.vue'),

                },
                {
                    path:      'shop/create',
                    name:      'ShopCreate',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/ShopsEditAdd.vue'),

                },
                {
                    path:      'shop/:id',
                    name:      'ShopEdit',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/Orpa/ShopsEditAdd.vue'),
                },
                {
                    path:      'orders/:id/defect',
                    component: () => import(/* webpackChunkName: "orpa" */ '@/components/ActShow.vue'),
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.getters.user.role === 4) {
                    next()
                } else {
                    next(false)
                }
            }
        },
        {
            path:        '/orpu',
            component:   () => import(/* webpackChunkName: "orpu" */ '@/views/Orpu/View.vue'),
            children:    [
                {
                    path:      '/',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/NewOrders.vue'),
                },
                {
                    path:      'prep-for-aggr',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/PreparationForAgreement.vue'),
                },
                {
                    path:      'for-client-approval',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/ForClientApproval.vue'),
                },
                {
                    path:      'orders-in-work',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/OrderInWork.vue'),
                },
                {
                    path:      'completed-orders',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/CompletedOrders.vue'),
                },
                {
                    path:      'canceled-orders',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/CanceledOrders.vue'),
                },
                {
                    path:      'closed-orders',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/ClosedOrders.vue'),
                },
                {
                    path:      'all-orders',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/AllOrders.vue'),
                },
                {
                    path:      'returned-orders',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/new/ReturnedOrders.vue'),
                },
                {
                    path:      'stations',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/Stations.vue'),
                },
                {
                    path:      'stations/add',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/StationEditAdd.vue'),
                },
                {
                    path:      'stations/:id',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/StationEditAdd.vue'),
                },
                {
                    path:      'evacuators/add',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/EvacuatorEditAdd.vue'),
                },
                {
                    path:      'evacuators/:id/edit',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/Orpu/EvacuatorEditAdd.vue'),
                },
                {
                    path:      'orders/:id/defect',
                    component: () => import(/* webpackChunkName: "orpu" */ '@/components/ActShow.vue'),
                },
            ],
            beforeEnter: (to, from, next) => {
                if (store.getters.user.role === 3) {
                    next()
                } else {
                    next(false)
                }
            }
        },
        {
            path:      "/accountant",
            component: () => import(/* webpackChunkName: "accountant" */ '@/views/Accountant/View.vue'),
            children:  [
                {
                    path:      '/:id',
                    name:      'AccountantView',
                    component: () => import(/* webpackChunkName: "accountant" */ '@/components/Accountant/Statuses.vue'),
                },
                {
                    path:      '/order/:id/defect',
                    name:      'AccountantDefect',
                    component: () => import(/* webpackChunkName: "accountant" */ '@/components/ActShow.vue'),
                },
            ]
        },
        {
            path: '/order/:id',
            name: 'OrderPage',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "order" */ '@/components/ActShow.vue')
        },
        {
            path: '/order/:id/defect',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "order" */ '@/views/Defect/View.vue'),
            children:  [
                {
                    path:      'create',
                    name:      'DefectCreate',
                    component: () => import(/* webpackChunkName: "order" */ '@/components/Defect/new/Create.vue'),
                },
            ],
        },
    ]
})
